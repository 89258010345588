import styled from 'styled-components'
import { themeGet } from 'styled-system'
import Button from 'components/Button'
import { ChevronIcon } from 'components/Icons'
import { fadeInAnimation } from 'styles/animation'
import { Container } from 'styles/sharedStyle'
import theme from 'styles/theme'

export const Wrapper = styled(Container)`
  color: ${theme.colors.sagedark};
  max-width: ${theme.maxWidths[1]};
  padding: 0 ${theme.space[4]};
  text-align: center;
`

export const Icon = styled.span`
  align-items: center;
  background: ${themeGet('colors.alabaster')};
  border-radius: 100%;
  display: flex;
  height: 16px;
  justify-content: center;
  margin-left: ${theme.space[2]};
  width: 16px;

  html[dir='rtl'] & {
    margin-left: 0;
    margin-right: ${theme.space[2]};
  }
`

export const MoreButton = styled(Button)`
  border: 0;
  cursor: pointer;
  padding: 0;
  background: ${(props) => props.colorBG};
  color: ${(props) => props.colorFont};
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background: ${(props) => props.colorBG ?? theme.colors.white};
    color: ${(props) => props.colorFont ?? themeGet('colors.sagedark')};
  }
`

export const MoreWrapper = styled.div.attrs(({ visible, colorFont }) => ({
  style: {
    display: visible ? 'block' : 'none',
  },
  colorFont: colorFont || theme.colors.sagemedium,
}))`
  animation: ${fadeInAnimation} 0.2s ease-in-out;
  margin: 0 auto;
  max-width: ${theme.maxWidths[0]};
  width: 100%;
  color: ${({ colorFont }) => colorFont};
  &::before {
    background-color: ${({ colorFont }) =>
      colorFont || theme.colors.sagemedium};
    content: '';
    display: block;
    height: 1px;
    margin: ${theme.space[10]} auto 0;
    width: 20px;
  }
`

export const MoreIcon = styled(ChevronIcon)`
  display: block;

  svg {
    display: inherit;
  }
`
